<template>
	<div class="justify-center">
		<div class="col-12 md:col-8">
			<div class="card card-w-title">
				<h5>Onboarding</h5>
				<p>Create an account to get access to a large pool of customers for your coaching business.</p>
				<Steps :model="nestedRouteItems"  />
				<router-view/>
			</div>
		</div>


	</div>
</template>

<script>
	export default {
		data() {
			return {
				nestedRouteItems: [
					{
						label: 'Personal',
						to: '/signup'
					},
					{
						label: 'Priorities',
						to: '/signupcoach/priorities'
					},
					{
						label: 'Completion',
						to: '/signupcoach/completion'
					}
				],
			}
		},
		methods: {
			toggleMenu(event) {
				this.$refs.menu.toggle(event);
			},
			onContextRightClick(event) {
				this.$refs.contextMenu.show(event);
			}
		}
	}
</script>
<style lang="scss">
@media (max-width: 450px) {
    .layout-wrapper .layout-main-container {
        padding-left: 0rem!important;
    }
    .layout-main-container {
        padding: 7rem 0rem 2rem 0rem!important;
    }
}
</style>
